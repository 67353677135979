#chat * {
  animation: none;
  animation-delay: 0;
  animation-direction: normal;
  animation-duration: 0;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: none;
  animation-play-state: running;
  animation-timing-function: ease;
  backface-visibility: visible;
  background: 0;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: transparent;
  background-image: none;
  background-origin: padding-box;
  background-position: 0 0;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: repeat;
  background-size: auto auto;
  border: 0;
  border-style: none;
  border-width: medium;
  border-color: inherit;
  border-bottom: 0;
  border-bottom-color: inherit;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-collapse: separate;
  border-image: none;
  border-left: 0;
  border-left-color: inherit;
  border-left-style: none;
  border-left-width: medium;
  border-radius: 0;
  border-right: 0;
  border-right-color: inherit;
  border-right-style: none;
  border-right-width: medium;
  border-spacing: 0;
  border-top: 0;
  border-top-color: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: none;
  border-top-width: medium;
  bottom: auto;
  box-shadow: none;
  box-sizing: content-box;
  caption-side: top;
  clear: none;
  clip: auto;
  color: inherit;
  columns: auto;
  column-count: auto;
  column-fill: balance;
  column-gap: normal;
  column-rule: medium none currentColor;
  column-rule-color: currentColor;
  column-rule-style: none;
  column-rule-width: none;
  column-span: 1;
  column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: ltr;
  display: inline;
  empty-cells: show;
  float: none;
  font: normal;
  font-family: inherit;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  height: auto;
  hyphens: none;
  left: auto;
  letter-spacing: normal;
  line-height: normal;
  list-style: none;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: disc;
  margin: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  orphans: 0;
  outline: 0;
  outline-color: invert;
  outline-style: none;
  outline-width: medium;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  padding: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  perspective: none;
  perspective-origin: 50% 50%;
  position: static;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes: "\201C""\201D""\2018""\2019";
  right: auto;
  tab-size: 8;
  table-layout: auto;
  text-align: inherit;
  text-align-last: auto;
  text-decoration: none;
  text-decoration-color: inherit;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  transform: none;
  transform-style: flat;
  transition: none;
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: none;
  transition-timing-function: ease;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 0;
  width: auto;
  word-spacing: normal;
  z-index: auto;
}

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&family=Open+Sans&display=swap");

/* Closed chat */
#open-chat {
  font-family: "Mulish", serif;
}

#open-chat.open-chat {
  display: flex;
  align-items: center;
  gap: 10px;
  position: fixed;
  bottom: 45px;
  right: 18px;
}

/* hidden elements */
#chat.closed,
#chat .zammad-scroll-hint,
#chat .zammad-chat-header-icon,
#chat .zammad-chat-agent,
#chat .zammad-chat-welcome,
#chat .zammad-chat-input,
#open-chat.open-chat.closed {
  display: none;
}

#open-chat .open-chat-text__container-text {
  display: flex;
  bottom: 61px;
  white-space: nowrap;
  font-size: 17px;
  line-height: 17px;
  border-radius: 16px;
  padding: 10px 15px;
  box-shadow: var(--dark-box-shadow);
  background: var(--white-background);
  cursor: pointer;
}

#open-chat .emoji {
  width: 20px;
  margin: 0 2px -5px;
  user-select: none;
}

#open-chat .open-chat-image {
  position: relative;
  width: 62px;
  height: 62px;
  cursor: pointer;
  border-radius: 100%;
  background: var(--gray-gradient);
  transition: all 0.3s ease;
}

#open-chat .open-chat-image:hover {
  background: var(--white-color);
  scale: 1.2;
}

#open-chat .open-chat-image svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Opened chat */

#chat .zammad-chat-header {
  position: relative;
  overflow: hidden;
  color: var(--white-color);
  background: var(--gray-gradient) !important;
  padding: 24px 28px 20px;
  border-radius: 16px 16px 0 0;
}

#chat.zammad-chat {
  background: #fff !important;
}

#chat .zammad-chat-header::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -70px;
  right: 0;
  width: 880%;
  height: 29px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzIgMTUiPgogIDxwYXRoIGQ9Ik0zNDkuOCAxLjRDMzM0LjUuNCAzMTguNSAwIDMwMiAwaC0yLjVjLTkuMSAwLTE4LjQuMS0yNy44LjQtMzQuNSAxLTY4LjMgMy0xMDIuMyA0LjctMTQgLjUtMjggMS4yLTQxLjUgMS42Qzg0IDcuNyA0MS42IDUuMyAwIDIuMnY4LjRjNDEuNiAzIDg0IDUuMyAxMjguMiA0LjEgMTMuNS0uNCAyNy41LTEuMSA0MS41LTEuNiAzMy45LTEuNyA2Ny44LTMuNiAxMDIuMy00LjcgOS40LS4zIDE4LjctLjQgMjcuOC0uNGgyLjVjMTYuNSAwIDMyLjQuNCA0Ny44IDEuNCA4LjQuMyAxNS42LjcgMjIgMS4yVjIuMmMtNi41LS41LTEzLjgtLjUtMjIuMy0uOHoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==);
}

#chat .zammad-chat-modal {
  padding: 0 28px;
}

#chat .zammad-chat-modal-text {
  padding-top: 5px;
  display: inline-block;
}

#chat .zammad-chat-modal-text strong {
  font-weight: bold;
}

#chat .zammad-chat-button.zammad-chat-send svg {
  transition: all 0.3s ease;
}

#chat .chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
}

#chat .chat-header__close {
  scale: 1;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

#chat .chat-header__close:hover {
  scale: 1.2;
  background: var(--gray-blue-background);
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

#chat .chat-header__close svg {
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translateX(-50%) rotate(-90deg);
  border-radius: 100%;
}

#chat .zammad-chat-status {
  align-self: center;
}

#chat .zammad-chat-agent-status[data-status="online"]::before,
#chat .zammad-chat-agent-status[data-status="offline"]::before {
  height: 8px;
  width: 8px;
  top: -1px;
}

#chat .chat-header__content {
  display: flex;
  gap: 18px;
}

#chat .chat-header__image {
  width: 52px;
  height: 52px;
}

#chat .chat-header__image-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 100%;
}

#chat .chat-header__text-kicker {
  display: block;
  font-size: 16px;
  line-height: 15px;
  margin-top: 4px;
}

#chat .chat-header__text-title {
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  margin-top: 4px;
}

#chat .chat-header__close,
#chat .chat-header__close svg,
#chat .chat-header__close svg path {
  cursor: pointer;
}

#chat {
  display: flex;
  font-family: "Mulish", serif;
  height: 600px;
  width: 372px;
  right: 40px;
  bottom: 30px !important;
  border-radius: 16px;
}

#chat .zammad-chat-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  padding: 12px 8px 12px 0;
  margin: 12px 15px 0 28px;
}

#chat .zammad-chat-message--agent .zammad-chat-message-body,
#chat .zammad-chat-message--customer .zammad-chat-message-body,
#chat .zammad-chat-timestamp {
  display: inline-block;
  padding: 10px 16px;
  border-radius: 20px;
  word-break: break-word;
}

#chat .zammad-chat-timestamp,
#chat .zammad-chat-message--agent,
#chat .zammad-chat-message--customer {
  min-height: min-content;
}

#chat .zammad-chat-message--customer,
#chat .zammad-chat-message--agent {
  max-width: 85%;
}

#chat .zammad-chat-timestamp {
  border: 1px solid var(--dark-gray-color);
  padding: 0 16px;
  align-self: center;
}

#chat .zammad-chat-message--agent {
  align-self: flex-start;
}

#chat .zammad-chat-message--customer {
  align-self: flex-end;
}

#chat .zammad-chat-message--agent .zammad-chat-message-body {
  background: var(--light-white-background) !important;
}

#chat .zammad-chat-message--customer .zammad-chat-message-body {
  color: var(--white-color);
  background: var(--gray-gradient) !important;
}

#chat .zammad-chat-controls {
  display: flex;
  border-top: 1px solid var(--gray-color);
  padding: 0 28px 28px;
  width: auto;
}

#chat .zammad-chat-custom-input {
  display: inline-block;
  flex: 0 1 95%;
  margin: 20px 0;
  max-height: 100px;
  overflow: auto;
}

#chat .zammad-chat--animate {
  -webkit-transition: -webkit-transform 500ms;
  transition: -webkit-transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
}

#chat .zammad-chat.zammad-chat-is-loaded {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
}

#chat .zammad-chat-send {
  position: absolute;
  right: -22px;
  height: 62px;
  width: 62px;
  bottom: 15px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.3s ease;
  background: var(--gray-gradient);
  box-shadow: var(--gray-box-shadow);
}

#chat .zammad-chat-controls .zammad-chat-button.js-restart {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  color: var(--white-color);
  background: var(--blue-background);
  border-radius: 10px;
  padding: 8px 16px;
  margin: 15px auto 0;
}

#chat .zammad-chat-send svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

#chat .zammad-chat-send svg path {
  cursor: pointer;
}

/* Support type */
#chat .zammad-chat-message--typing .zammad-chat-message-body {
  visibility: hidden;
  position: relative;
}

#chat .zammad-chat-message--typing .zammad-chat-message-body:after {
  visibility: visible;
  position: absolute;
  width: 11px;
  top: 0;
  left: 0;
  content: "";
  background: var(--light-white-background) !important;
  padding: 3px 15px;
  border-radius: 20px;
  animation-name: example;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes example {
  0% {
    content: ".";
  }
  50% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

/* width */
#chat ::-webkit-scrollbar {
  width: 6px;
}

/* Track */
#chat ::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
#chat ::-webkit-scrollbar-thumb {
  background-color: var(--dark-blue-transparent);
  border-radius: 10px;
}

/* Handle on hover */
#chat ::-webkit-scrollbar-thumb:hover {
  background-color: var(--dark-blue-background);
}
