@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9hkqv7');
  src:  url('fonts/icomoon.eot?9hkqv7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9hkqv7') format('truetype'),
    url('fonts/icomoon.woff?9hkqv7') format('woff'),
    url('fonts/icomoon.svg?9hkqv7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-unlocked:before {
  content: "\e95e";
}
.icon-locked:before {
  content: "\e95f";
}
.icon-Unselect:before {
  content: "\e95c";
}
.icon-Select:before {
  content: "\e95a";
}
.icon-Select-All:before {
  content: "\e95b";
}
.icon-Unselect-All:before {
  content: "\e95d";
}
.icon-Board-Grid:before {
  content: "\e958";
}
.icon-Board-Applied:before {
  content: "\e959";
}
.icon-GMRX:before {
  content: "\e956";
}
.icon-solana:before {
  content: "\e955";
}
.icon-Trash-2:before {
  content: "\e954";
}
.icon-Trash:before {
  content: "\e953";
}
.icon-Settings:before {
  content: "\e94c";
}
.icon-Support:before {
  content: "\e957";
}
.icon-Dollar:before {
  content: "\e94b";
}
.icon-Gaimin-Gladiators:before {
  content: "\e94a";
}
.icon-Attempts:before {
  content: "\e945";
}
.icon-Reward:before {
  content: "\e946";
}
.icon-Games-Dota2:before {
  content: "\e944";
}
.icon-Games-Fortnite:before {
  content: "\e948";
}
.icon-Games-PUBG-Mobile:before {
  content: "\e949";
}
.icon-Games-RocketLeague:before {
  content: "\e943";
}
.icon-Infinite:before {
  content: "\e942";
}
.icon-Change-Password:before {
  content: "\e941";
}
.icon-hours:before {
  content: "\e940";
}
.icon-collections:before {
  content: "\e93f";
}
.icon-hedera:before {
  content: "\e93e";
}
.icon-Ticket:before {
  content: "\e93d";
}
.icon-Place:before {
  content: "\e93c";
}
.icon-Vector:before {
  content: "\e93b";
  color: #595c64;
}
.icon-Events:before {
  content: "\e93a";
}
.icon-Calendar:before {
  content: "\e939";
}
.icon-Eye:before {
  content: "\e938";
}
.icon-Closed-Eye:before {
  content: "\e937";
}
.icon-polygon:before {
  content: "\e935";
  color: #8247e5;
}
.icon-bnb:before {
  content: "\e936";
  color: #f3ba2f;
}
.icon-Nep-Squad-2:before {
  content: "\e934";
}
.icon-Nep-Lock:before {
  content: "\e933";
}
.icon-quiz:before {
  content: "\e932";
}
.icon-cup:before {
  content: "\e931";
}
.icon-donate:before {
  content: "\e930";
}
.icon-Streams:before {
  content: "\e92f";
}
.icon-Login-Button:before {
  content: "\e92d";
}
.icon-TopBar-Minimize:before {
  content: "\e900";
}
.icon-TopBar-Maximize:before {
  content: "\e915";
}
.icon-Close-Bold:before {
  content: "\e902";
}
.icon-Menu:before {
  content: "\e903";
}
.icon-Games:before {
  content: "\e905";
}
.icon-Games-2:before {
  content: "\e910";
}
.icon-Nep-Squad:before {
  content: "\e901";
}
.icon-Market:before {
  content: "\e929";
}
.icon-Market-2:before {
  content: "\e90e";
}
.icon-Home:before {
  content: "\e907";
}
.icon-Dashboard:before {
  content: "\e90a";
}
.icon-Collection:before {
  content: "\e908";
}
.icon-Wallet:before {
  content: "\e909";
}
.icon-Site:before {
  content: "\e90b";
}
.icon-Dot:before {
  content: "\e90c";
}
.icon-Notification:before {
  content: "\e90d";
}
.icon-Success:before {
  content: "\e91e";
}
.icon-Error:before {
  content: "\e92a";
}
.icon-Withdraw:before {
  content: "\e90f";
}
.icon-Achievements:before {
  content: "\e913";
}
.icon-Benchmarking:before {
  content: "\e925";
}
.icon-Search:before {
  content: "\e912";
}
.icon-Copy:before {
  content: "\e911";
}
.icon-Copy-2:before {
  content: "\e914";
}
.icon-Refresh:before {
  content: "\e904";
}
.icon-Close:before {
  content: "\e928";
}
.icon-Edit:before {
  content: "\e916";
}
.icon-Soc-Whatsapp:before {
  content: "\e906";
}
.icon-Soc-Twitch:before {
  content: "\e92e";
}
.icon-Soc-Discord:before {
  content: "\e947";
}
.icon-Soc-Telegram:before {
  content: "\e918";
}
.icon-Soc-Twitter-New:before {
  content: "\e94f";
}
.icon-Soc-Youtube:before {
  content: "\e952";
}
.icon-Soc-Instagram:before {
  content: "\e94e";
}
.icon-Soc-Facebook:before {
  content: "\e919";
}
.icon-Soc-Twitter:before {
  content: "\e91a";
}
.icon-Soc-Weibo:before {
  content: "\e951";
}
.icon-Soc-Tiktok:before {
  content: "\e950";
}
.icon-Soc-Share:before {
  content: "\e91b";
}
.icon-Upload:before {
  content: "\e91c";
}
.icon-Attachment:before {
  content: "\e91d";
}
.icon-Dots:before {
  content: "\e91f";
}
.icon-Dots-2:before {
  content: "\e920";
}
.icon-Checked:before {
  content: "\e921";
}
.icon-Arrow-Bottom:before {
  content: "\e922";
}
.icon-Arrow-Top:before {
  content: "\e92b";
}
.icon-Arrow-Left:before {
  content: "\e923";
}
.icon-Arrow-Right:before {
  content: "\e924";
}
.icon-Star:before {
  content: "\e917";
}
.icon-Zipper:before {
  content: "\e926";
}
.icon-Zipper-2:before {
  content: "\e927";
}
.icon-Invalid:before {
  content: "\e92c";
}
.icon-Chat-Collapse:before {
  content: "\e94d";
}
